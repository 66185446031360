export type DefaultFormInputs = {
  apiError: string
}

export type ContactFormInputs = DefaultFormInputs & {
  topic: ReadonlyArray<{ label: string; value: string | number }>
  subject: string
  message: string
  firstName: string
  lastName: string
  emailAddress: string
  busPhone: string
  company: string
  country: string
  consent: boolean
  isPortalRecipient: boolean
}

export type NewsletterFormInputs = DefaultFormInputs & {
  emailAddress: string
  company: string
  marketingPermission: boolean
}

/**
 * regular expression will match and validate phone numbers that are in a variety of
 * different formats, such as +1 (123) 456-7890 or 123-456-7890.
 */
export const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/i

export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
