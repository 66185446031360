import { Stack } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, ReactNode } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import { ContactEmailData, EMAILS_API_ROUTES } from 'config/email'
import { ContactFormInputs } from 'config/forms'
import useGoogleReCaptcha from 'hooks/useGoogleReCaptcha'

interface ContactFormProviderProps {
  children: ReactNode
}

const TAG = 'ContactFormProvider'

const ContactFormProvider: FunctionComponent<ContactFormProviderProps> = ({ children }) => {
  const form = useForm<ContactFormInputs>({
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })
  const { t } = useTranslation()
  const { handleSubmit, setError } = form

  const { executeReCaptcha } = useGoogleReCaptcha()

  const handleSubmitError = (error?: string) => {
    console.error('Error sending email:', error)
    setError('apiError', { message: t('components.contactForm.errors.error-form-api') })
  }

  const onSubmit: SubmitHandler<ContactFormInputs> = async (data) => {
    const reCaptchaResponseToken = await executeReCaptcha('LoadReCaptchaOnStart')

    const {
      subject,
      topic,
      isPortalRecipient,
      firstName,
      lastName,
      emailAddress,
      busPhone,
      company,
      country,
      message,
    } = data

    const templateData: ContactEmailData = {
      email: emailAddress,
      fullName: `${firstName} ${lastName}`,
      phone: busPhone,
      message,
      company,
      country,
    }

    return fetch(EMAILS_API_ROUTES.ContactEmail, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Recaptcha-Token': reCaptchaResponseToken,
      },
      body: JSON.stringify({
        subject,
        topic,
        isPortalRecipient,
        templateData,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = await response.text()
          handleSubmitError(error)
        }
      })
      .catch((error) => handleSubmitError(error))
  }

  return (
    <FormProvider {...form}>
      <Stack as="form" name="contactForm" onSubmit={handleSubmit(onSubmit)} spacing={6}>
        {children}
      </Stack>
    </FormProvider>
  )
}

ContactFormProvider.displayName = TAG

export default ContactFormProvider
