export const FALLBACK_SENDER_NAME = 'Normet Webform'
export const FALLBACK_SENDER_ADDRESS = 'portal@normet.com'

export const EMAILS_API_ROUTES = {
  ContactEmail: '/api/emails/contact-email/',
  NewsletterEmail: '/api/emails/newsletter-email/',
  DynamicFormEmail: '/api/emails/dynamic-form-email/',
} as const satisfies Record<string, string>

export interface ContactEmailData {
  message: string
  fullName: string
  company: string
  email: string
  phone: string
  country: string
}

export interface NewsletterEmailData {
  company: string
  email: string
}

export interface DynamicFormEmailData {
  message: string
  email?: string
}
