import { Text, Button, Container, Stack, Box } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, useEffect, useRef } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'

import DynamicField from 'components/elements/DynamicForm/DynamicField'
import { FormField, DynamicForm as Form } from 'components/elements/DynamicForm/types'
import CardAlert from 'components/elements/WarningAlert'
import { DynamicFormEmailData, EMAILS_API_ROUTES } from 'config/email'
import useGoogleReCaptcha from 'hooks/useGoogleReCaptcha'
import removeEmptyValues from 'utils/removeEmptyValues'

interface DynamicFormProps {
  formName: string
  fields: FormField[] | undefined
  entryId?: string
}

const styles = {
  container: { maxW: 'main-block-lg', py: 0, px: { base: 10, xl: 0 }, my: 6 },
  button: { w: 'min-content', mt: '40px !important' },
} as const

const TAG = 'DynamicForm'

const DynamicForm: FunctionComponent<DynamicFormProps> = ({ formName, fields, entryId }) => {
  const { t } = useTranslation()
  const { executeReCaptcha } = useGoogleReCaptcha()

  const form = useForm<Form>({
    reValidateMode: 'onChange',
    mode: 'onBlur',
  })

  const {
    handleSubmit,
    setError,
    formState: { errors, isValid, isSubmitting, isSubmitted, isSubmitSuccessful },
  } = form

  const handleSubmitError = (error?: string) => {
    console.error('Error sending email:', error)
    setError('apiError', { message: t('components.contactForm.errors.error-form-api') })
  }

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const reCaptchaResponseToken = await executeReCaptcha('LoadReCaptchaOnStart')

    let message = ''
    let email = ''
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        const field = fields?.find((field) => field.htmlName === key)

        if (field?.htmlName?.toLowerCase().includes('email')) {
          email = value
        }

        message += `<p><b>${field?.label}:</b> <span>${value}</span><p><br>`
      }
    })

    const templateData: DynamicFormEmailData = { message, email }

    return fetch(EMAILS_API_ROUTES.DynamicFormEmail, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Recaptcha-Token': reCaptchaResponseToken,
      },
      body: JSON.stringify({ subject: formName, templateData }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = await response.text()
          handleSubmitError(error)
          return
        }
      })
      .catch((error) => {
        handleSubmitError(error)
        return
      })
  }

  const successAlertRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (isSubmitSuccessful) {
      successAlertRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isSubmitSuccessful])

  return (
    <Container
      data-testid={TAG}
      {...styles.container}
      data-contentful-field-id="fields"
      data-contentful-entry-id={entryId}
    >
      <FormProvider {...form}>
        {isSubmitSuccessful && !errors?.apiError ? (
          <Box ref={successAlertRef}>
            <CardAlert>
              <Text>{t('components.dynamicForm.success.title')}</Text>
            </CardAlert>
          </Box>
        ) : (
          <Stack as="form" name="form" onSubmit={handleSubmit(onSubmit)} spacing={6}>
            {removeEmptyValues(fields).map((field) => (
              <DynamicField key={field.htmlName} field={field} />
            ))}

            {errors?.apiError && (
              <CardAlert variant="warning">
                <Text>{errors?.apiError?.message as string}</Text>
              </CardAlert>
            )}

            <Button
              isLoading={isSubmitting}
              isDisabled={isSubmitting || (isSubmitted && !isValid)}
              type="submit"
              {...styles.button}
            >
              {t('components.dynamicForm.submit')}
            </Button>
          </Stack>
        )}
      </FormProvider>
    </Container>
  )
}

export default DynamicForm
